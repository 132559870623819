import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { BarChart3Icon } from "lucide-react"
import React from "react"
import { getTopRecordings } from "../ApiClient"
import { epoch, roundedNow, useAutoQuery } from "../utils"
import { Link } from "react-router-dom"

export function ArtistTopRecordingCard({artistId}) {
    const start = epoch()
    const end = roundedNow()

    const {
        data: groups,
        isPending,
    } = useAutoQuery(getTopRecordings, artistId, null, null, start, end, null, 20)

    if (isPending || groups.length === 0) {
        return
    }

    const group = groups[0]
    const recording = group.recording

    return (
        <Link to={`/track/${recording.id}`}>
            <Card>
                <CardHeader className="flex flex-row items-center justify-between">
                    <CardTitle className="text-sm font-medium">Top Track</CardTitle>
                    <BarChart3Icon className="w-4 h-4 text-muted-foreground"/>
                </CardHeader>
                <CardContent>
                    <div className="text-2xl font-bold">{recording.name}</div>
                    <p className="text-xs text-muted-foreground">{group.count} plays all time</p>
                </CardContent>
            </Card>
        </Link>
    )
}