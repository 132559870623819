import { Button } from "./ui/button"
import { PlayCircleIcon } from "lucide-react"
import React from "react"

export function ListenOnSpotifyButton({url}) {
    return (
        <a href={url} target="_blank" rel="noopener noreferrer">
            <Button className="bg-spotify hover:bg-spotify-dark flex flex-row gap-1">
                <PlayCircleIcon/>
                Listen on Spotify
            </Button>
        </a>
    )
}