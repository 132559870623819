import { create } from "zustand"

export const tokenStore = create((set) => ({
    token: localStorage.getItem("token"),
    setToken: (token) => {
        localStorage.setItem("token", token)
        set({token: token})
    },
    clearToken: () => {
        localStorage.removeItem("token")
        set({token: null})
    },
}))
