import { TopArtists } from "../TopArtists"
import { TopReleaseGroups } from "../TopReleaseGroups"
import React from "react"
import { TopRecordings } from "../TopRecordings"
import { getArtistName, roundedNow, useAutoQuery } from "../../utils"
import { TopTags } from "../TopTags"
import { Graph } from "../Graph"
import { getArtistsGraph } from "../../ApiClient"

function ArtistGraph({start, end}) {
    const {data: graph, isPending} = useAutoQuery(getArtistsGraph, start, end)

    if (isPending) {
        return
    }

    return (
        <Graph
            graph={graph}
            nameFormatter={getArtistName}
        />
    )
}


export function RecentDashboardBody() {
    const end = roundedNow()
    const start = end.minus({days: 30})

    return (
        <div className="w-full flex flex-col gap-4">
            <ArtistGraph start={start} end={end}/>
            <div className="w-full grid gap-4 sm:grid-cols-1 md:grid-cols-2">
                <TopArtists start={start} end={end}/>
                <TopReleaseGroups start={start} end={end}/>
                <TopRecordings start={start} end={end}/>
                <TopTags start={start} end={end}/>
            </div>
        </div>
    )
}