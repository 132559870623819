import { useEffect, useState } from "react"
import { getUnmatchedSpotifySummary } from "../../ApiClient"
import { Card, CardContent, CardHeader } from "../ui/card"

export function UnmatchedSpotifySummary() {
    const [summary, setSummary] = useState(null)

    useEffect(() => {
        getUnmatchedSpotifySummary().then(setSummary)
    }, [])

    if (summary === null) {
        return
    }

    return (
        <Card>
            <CardHeader className="text-xl font-semibold">
                Summary
            </CardHeader>
            <CardContent>
                <p>Unmatched listens: {summary.listenCount}</p>
                <p>Unmatched tracks: {summary.trackCount}</p>
            </CardContent>
        </Card>
    )
}