import React, { useEffect, useState } from "react"
import { DashboardTimeFilters } from "../components/DashboardTimeFilters"
import { RecentDashboardBody } from "../components/dashboard/RecentDashboardBody"
import { AllTimeDashboardBody } from "../components/dashboard/AllTimeDashboardBody"
import { YearDashboardBody } from "../components/dashboard/YearDashboardBody"
import { tokenStore } from "../stores/ApiTokenStore"
import { useNavigate } from "react-router-dom"
import { currentUserStore } from "../stores/CurrentUserStore"
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar"
import { UploadSpotifyListeningHistoryWidget } from "../components/UploadSpotifyListeningHistoryWidget"
import { useAutoQuery } from "../utils"
import { getSpotifyBackfillImports } from "../ApiClient"
import { UnmatchedListensWidget } from "../components/UnmatchedListensWidget"

function Header() {
    const currentUser = currentUserStore(state => state.currentUser)
    const {data: imports, isPending: importsPending} = useAutoQuery(getSpotifyBackfillImports)

    if (!currentUser) {
        return
    }

    const showImportWidget = !importsPending && imports.length === 0

    return (
        <div className="flex flex-row flex-wrap gap-4 items-center justify-around h-full w-full">
            <div className="flex flex-row gap-4 items-center">
                <Avatar className="h-[250px] w-[250px]">
                    <AvatarImage src={currentUser.images[0]?.url}/>
                    <AvatarFallback>CN</AvatarFallback>
                </Avatar>
                <div className="flex flex-col gap-4">
                    <h1 className="text-4xl font-bold">{currentUser.name}</h1>
                </div>
            </div>
            {showImportWidget ? <UploadSpotifyListeningHistoryWidget/> : null}
            <UnmatchedListensWidget/>
        </div>
    )
}

export function DashboardPage() {
    const [selectedFilter, setSelectedFilter] = useState("recent")
    const navigate = useNavigate()
    const token = tokenStore.getState().token

    useEffect(() => {
        if (token === null) {
            navigate("/")
        }
    }, [token, navigate])

    if (token === null) {
        return
    }

    let tab = null
    if (selectedFilter === "recent") {
        tab = <RecentDashboardBody/>
    } else if (selectedFilter === "allTime") {
        tab = <AllTimeDashboardBody/>
    } else {
        const selectedFilterAsNumber = parseInt(selectedFilter, 10)
        if (!isNaN(selectedFilterAsNumber)) {
            tab = <YearDashboardBody year={selectedFilterAsNumber}/>
        }
    }

    return (
        <div className="flex flex-col items-center gap-4 w-full overflow-hidden">
            <Header/>
            <DashboardTimeFilters
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
            />
            {tab}
        </div>
    );
}
