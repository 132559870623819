import { epoch, roundedNow, useAutoQuery } from "../utils"
import { getTag } from "../ApiClient"
import { useParams } from "react-router-dom"
import { TopRecordings } from "../components/TopRecordings"
import { Badge } from "../components/ui/badge"
import { TopArtists } from "../components/TopArtists"

function Header({tagId}) {
    const {data: tag, isPending} = useAutoQuery(getTag, tagId)


    if (isPending) {
        return
    }

    return (
        <div className="flex flex-row items-center justify-between">
            <h1 className="text-4xl font-bold">{tag.name}</h1>
            <Badge variant="outline" className="text-lg">Genre Insights</Badge>
        </div>
    )
}

export function TagPage() {
    const {tagId} = useParams()

    const start = epoch()
    const end = roundedNow()

    return (
        <div className="flex flex-col gap-4">
            <Header tagId={tagId}/>
            <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                <TopRecordings tagId={tagId} start={start} end={end} limit="10"/>
                <TopArtists tagId={tagId} start={start} end={end} limit="10"/>
            </div>
        </div>
    )
}