import { HomePage } from "./pages/HomePage"
import { AboutPage } from "./pages/AboutPage"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ArtistPage } from "./pages/ArtistPage"
import { AuthPage } from "./pages/AuthPage"
import { DashboardPage } from "./pages/DashboardPage"
import { Layout } from "./components/Layout"
import { ReleaseGroupPage } from "./pages/ReleaseGroupPage"
import { RecordingPage } from "./pages/RecordingPage"
import { DataFixerUnmatchedSpotifyAlbumsPage } from "./pages/datafixer/DataFixerUnmatchedSpotifyAlbumsPage"
import { DataFixerPage } from "./pages/datafixer/DataFixerPage"
import { LoginPage } from "./pages/LoginPage"
import { SpotifyDataUploadPage } from "./pages/SpotifyDataUploadPage"
import { DataFixerUnmatchedSpotifyTracksPage } from "./pages/datafixer/DataFixerUnmatchedSpotifyTracksPage"
import { TagPage } from "./pages/TagPage"
import { DataFixerUnmatchedSpotifyArtistsPage } from "./pages/datafixer/DataFixerUnmatchedSpotifyArtistsPage"
import { SearchPage } from "./pages/SearchPage"
import { UserPage } from "./pages/UserPage"

export function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/u/:userId" element={<UserPage/>}/>
                    <Route path="/artist/:artistId" element={<ArtistPage/>}/>
                    <Route path="/album/:releaseGroupId" element={<ReleaseGroupPage/>}/>
                    <Route path="/track/:recordingId" element={<RecordingPage/>}/>
                    <Route path="/genre/:tagId" element={<TagPage/>}/>
                    <Route path="/auth" element={<AuthPage/>}/>
                    <Route path="/dashboard" element={<DashboardPage/>}/>
                    <Route path="/search" element={<SearchPage/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/spotify-data-upload" element={<SpotifyDataUploadPage/>}/>
                    <Route path="/dataFixer" element={<DataFixerPage/>}/>
                    <Route path="/dataFixer/unmatchedSpotifyAlbums" element={<DataFixerUnmatchedSpotifyAlbumsPage/>}/>
                    <Route path="/dataFixer/unmatchedSpotifyTracks" element={<DataFixerUnmatchedSpotifyTracksPage/>}/>
                    <Route path="/dataFixer/unmatchedSpotifyArtists" element={<DataFixerUnmatchedSpotifyArtistsPage/>}/>
                </Routes>
            </Layout>
        </Router>
    )
}
