import { Link } from "react-router-dom"
import React from "react"
import { UnmatchedSpotifySummary } from "../../components/datafixing/UnmatchedSpotifySummary"
import { Button } from "../../components/ui/button"

export function DataFixerPage() {
    return (
        <div className="flex flex-col items-center gap-4">
            <UnmatchedSpotifySummary/>
            <Link to="/datafixer/unmatchedSpotifyTracks">
                <Button className="bg-purple-500 hover:bg-purple-600 text-white">
                    Top Unmatched Spotify Tracks
                </Button>
            </Link>
            <Link to="/datafixer/unmatchedSpotifyAlbums">
                <Button className="bg-purple-500 hover:bg-purple-600 text-white">
                    Top Unmatched Spotify Albums
                </Button>
            </Link>
            <Link to="/datafixer/unmatchedSpotifyArtists">
                <Button className="bg-purple-500 hover:bg-purple-600 text-white">
                    Top Unmatched Spotify Artists
                </Button>
            </Link>
        </div>
    )
}