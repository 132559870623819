import React from "react"
import { Header } from "./Header"

export function Layout({children}) {
    return (
        <div
            className="flex flex-col items-center w-full min-h-screen bg-purple-50 dark:from-gray-900 dark:to-gray-800">
            <Header/>
            <main className="flex-grow w-full max-w-6xl p-4 flex flex-col items-center">
                {children}
            </main>
        </div>
    );
}
