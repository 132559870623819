import { Link } from "react-router-dom"
import { Button } from "./ui/button"

export function LoginButton() {
    return (
        <Link to="/login">
            <Button className="text-white bg-purple-600 hover:bg-purple-700">
                Login
            </Button>
        </Link>
    )
}