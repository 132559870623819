import { TopReleaseGroups } from "../TopReleaseGroups"
import React from "react"
import { getSummary, getTopTags } from "../../ApiClient"
import { TopArtists } from "../TopArtists"
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card"
import { BarChart3Icon, ClockIcon, HeadphonesIcon, UsersIcon } from "lucide-react"
import { TopRecordings } from "../TopRecordings"
import { epoch, formatCount, formatListenDuration, roundedNow, useAutoQuery } from "../../utils"
import { TopTags } from "../TopTags"

function ListenCountSummaryCard() {
    const start = epoch()
    const end = roundedNow()
    const {data: summary, isPending} = useAutoQuery(getSummary, start, end)

    if (isPending) {
        return
    }

    return (
        <Card className="bg-gradient-to-br from-purple-500 to-indigo-500 text-white">
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Total Listens</CardTitle>
                <HeadphonesIcon className="w-4 h-4 text-purple-200"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{formatCount(summary.listenCount)}</div>
                <p className="text-xs text-purple-200">+20.1% recently</p>
            </CardContent>
        </Card>
    )
}

function ArtistCountSummaryCard() {
    const start = epoch()
    const end = roundedNow()
    const {data: summary, isPending} = useAutoQuery(getSummary, start, end)

    if (isPending) {
        return
    }

    return (
        <Card className="bg-gradient-to-br from-blue-500 to-cyan-500 text-white">
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Unique Artists</CardTitle>
                <UsersIcon className="w-4 h-4 text-blue-200"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{formatCount(summary.artistCount)}</div>
                <p className="text-xs text-blue-200">+12.5% recently</p>
            </CardContent>
        </Card>
    )
}

function TopGenreSummaryCard() {
    const start = epoch()
    const end = roundedNow()
    const {data: topTags, isPending: topTagsPending} = useAutoQuery(getTopTags, start, end)
    const {data: summary, isPending: summaryPending} = useAutoQuery(getSummary, start, end)

    if (topTagsPending || summaryPending) {
        return
    }

    const tagGroup = topTags[0]

    if (tagGroup == null) {
        return
    }

    let totalPercent = tagGroup.count / summary.listenCount * 100
    totalPercent = totalPercent.toFixed(0)

    return (
        <Card className="bg-gradient-to-br from-green-500 to-emerald-500 text-white">
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Top Genre</CardTitle>
                <BarChart3Icon className="w-4 h-4 text-green-200"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{tagGroup.tag.name}</div>
                <p className="text-xs text-green-200">{totalPercent}% of total listens</p>
            </CardContent>
        </Card>
    )
}

function ListeningTimeSummaryCard() {
    const start = epoch()
    const end = roundedNow()
    const {data: summary, isPending} = useAutoQuery(getSummary, start, end)

    if (isPending) {
        return
    }

    return (
        <Card className="bg-gradient-to-br from-orange-500 to-red-500 text-white">
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Listening Time</CardTitle>
                <ClockIcon className="w-4 h-4 text-orange-200"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{formatListenDuration(summary.durationMs)}</div>
                <p className="text-xs text-orange-200">+5.2% recently</p>
            </CardContent>
        </Card>
    )
}

function Summary() {
    return (
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4">
            <ListenCountSummaryCard/>
            <ArtistCountSummaryCard/>
            <TopGenreSummaryCard/>
            <ListeningTimeSummaryCard/>
        </div>
    )
}

export function AllTimeDashboardBody() {
    const start = epoch()
    const end = roundedNow()

    return (
        <div className="flex flex-col gap-4 w-full">
            <Summary/>
            <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
                <TopArtists start={start} end={end}/>
                <TopReleaseGroups start={start} end={end}/>
                <TopRecordings start={start} end={end}/>
                <TopTags start={start} end={end}/>
            </div>
        </div>
    )
}