import { Link } from "react-router-dom"
import { BarChart3, ClockIcon } from "lucide-react"
import { formatCount, formatListenDuration } from "../utils"
import React from "react"
import { Badge } from "./ui/badge"

export function RecordingCard({group}) {
    const recording = group.recording

    return (
        <Link to={`/track/${recording.id}`} className="flex flex-col">
            <div className="rounded-t-md border-t border-x">
                <img src={recording.images[0]?.url} alt={recording.name} className="rounded-t-md object-cover aspect-square"/>
            </div>
            <div className="flex flex-col gap-1 border rounded-b-md p-2">
                <span className="text-md font-semibold text-nowrap truncate">{recording.name}</span>
                <span className="text-sm text-nowrap truncate">{recording.artistCredit.name}</span>
                <Badge variant="secondary">
                    <BarChart3 className="w-3 h-3"/>
                    <span className="text-xs">{formatCount(group.count)} plays</span>
                </Badge>
                <Badge variant="secondary">
                    <ClockIcon className="w-3 h-3"/>
                    <span className="text-xs">{formatListenDuration(group.durationMs)}</span>
                </Badge>
            </div>
        </Link>
    )
}