import React from "react"
import { getTopYears } from "../ApiClient"
import { useAutoQuery } from "../utils"

function DashboardTimeFilter({name, isActive, onClick}) {

    const activeClasses = "bg-purple-500 text-white border-transparent"
    const inactiveClasses = "bg-white text-gray-800 hover:bg-gray-100"

    return (
        <div
            onClick={onClick}
            className={`px-4 py-2 rounded-full border transition-all duration-200 whitespace-nowrap cursor-pointer shadow-sm ${isActive ? activeClasses : inactiveClasses}`}>
            <span>{name}</span>
        </div>
    )
}

export function DashboardTimeFilters({selectedFilter, setSelectedFilter}) {
    const {data: topYears, isPending} = useAutoQuery(getTopYears, 10)

    const filters = [
        {name: "Recent", key: "recent"},
        {name: "All time", key: "allTime"},
    ]
    if (!isPending) {
        topYears
            .filter(group => group.count >= 5)
            .sort((a, b) => b.year - a.year)
            .map(group => group.year)
            .map(year => {
                return {name: year, key: year}
            })
            .forEach(filter => filters.push(filter))
    }

    return (
        <div className="custom-scrollbar max-w-full overflow-x-auto">
            <div className="inline-flex flex-row gap-4">
                {filters.map(filter => (
                    <DashboardTimeFilter
                        key={filter.key}
                        name={filter.name}
                        isActive={selectedFilter === filter.key}
                        onClick={() => setSelectedFilter(filter.key)}
                    />
                ))}
            </div>
        </div>
    );
}
