import React from "react"
import { useParams } from "react-router-dom"
import { getRecordingsGraph, getReleaseGroup, getReleaseGroupSummary } from "../ApiClient"
import {
    epoch,
    formatDateHumanReadable,
    formatListenDuration,
    formatReleaseDate,
    getReleaseGroupName,
    roundedNow,
    useAutoQuery,
} from "../utils"
import { CalendarIcon, Clock3Icon, HeadphonesIcon, SparklesIcon, TrendingUpIcon } from "lucide-react"
import { ListenOnSpotifyButton } from "../components/ListenOnSpotifyButton"
import { ArtistCredit } from "../components/ArtistCredit"
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card"
import { TopRecordings } from "../components/TopRecordings"
import { Graph } from "../components/Graph"

function Header({releaseGroupId}) {
    const {data: releaseGroup, isPending} = useAutoQuery(getReleaseGroup, releaseGroupId)

    if (isPending) {
        return null
    }

    const name = getReleaseGroupName(releaseGroup)

    return (
        <div className="flex flex-col md:flex-row gap-6 items-start">
            <img
                src={releaseGroup.images[0].url}
                alt={name}
                className="w-64 h-64 object-cover rounded-lg shadow-lg"
            />
            <div className="flex flex-col gap-2">
                <h1 className="text-3xl md:text-4xl font-bold">{name}</h1>
                <ArtistCredit artistCredit={releaseGroup.artistCredit}/>
                <div className="flex gap-4">
                    <ListenOnSpotifyButton url={releaseGroup.spotifyUrl}/>
                </div>
                <ReleaseDate releaseDate={releaseGroup.releaseDate}/>
                <div className="flex items-center text-sm text-gray-600">
                    <Clock3Icon className="w-4 h-4 mr-1"/>
                    44 min 8 sec
                </div>
            </div>
        </div>
    )
}

function RecordingsGraph({releaseGroupId}) {
    const start = epoch()
    const end = roundedNow()
    const {data: graph, isPending} = useAutoQuery(getRecordingsGraph, null, releaseGroupId, start, end)

    if (isPending) {
        return
    }

    return (
        <Graph
            graph={graph}
            nameFormatter={recording => recording.name}
            className="w-full h-[25rem]"
        />
    )
}

function ReleaseDate({releaseDate}) {
    let formatted = formatReleaseDate(releaseDate)

    if (formatted === null) {
        return null
    }

    return (
        <div className="flex items-center text-sm text-gray-600">
            <CalendarIcon className="w-4 h-4 mr-1"/>
            {formatted}
        </div>
    )
}

function Summary({releaseGroupId}) {
    return (
        <div className="grid w-full gap-4 md:grid-cols-2 lg:grid-cols-4">
            <ListenCountSummaryCard releaseGroupId={releaseGroupId}/>
            <ListeningTimeSummaryCard releaseGroupId={releaseGroupId}/>
            <RankSummaryCard releaseGroupId={releaseGroupId}/>
            <FirstListenSummaryCard releaseGroupId={releaseGroupId}/>
        </div>
    )
}

function ListenCountSummaryCard({releaseGroupId}) {
    const start = epoch()
    const end = roundedNow()
    const recentStart = roundedNow().minus({days: 30})
    const {
        data: allTimeSummary,
        isPending: allTimeSummaryPending,
    } = useAutoQuery(getReleaseGroupSummary, releaseGroupId, start, end)
    const {
        data: recentSummary,
        isPending: recentSummaryPending,
    } = useAutoQuery(getReleaseGroupSummary, releaseGroupId, recentStart, end)

    if (allTimeSummaryPending || recentSummaryPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Total Listens</CardTitle>
                <HeadphonesIcon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{allTimeSummary.listenCount}</div>
                <p className="text-xs text-muted-foreground">+{recentSummary.listenCount} recently</p>
            </CardContent>
        </Card>
    )
}

function ListeningTimeSummaryCard({releaseGroupId}) {
    const start = epoch()
    const end = roundedNow()
    const recentStart = roundedNow().minus({days: 30})
    const {
        data: allTimeSummary,
        isPending: allTimeSummaryPending,
    } = useAutoQuery(getReleaseGroupSummary, releaseGroupId, start, end)
    const {
        data: recentSummary,
        isPending: recentSummaryPending,
    } = useAutoQuery(getReleaseGroupSummary, releaseGroupId, recentStart, end)

    if (allTimeSummaryPending || recentSummaryPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Listening Time</CardTitle>
                <Clock3Icon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{formatListenDuration(allTimeSummary.durationMs)}</div>
                <p className="text-xs text-muted-foreground">+{formatListenDuration(recentSummary.durationMs)} from
                    last month</p>
            </CardContent>
        </Card>
    )
}

function RankSummaryCard({releaseGroupId}) {
    const start = epoch()
    const end = roundedNow()
    const {data: summary, isPending} = useAutoQuery(getReleaseGroupSummary, releaseGroupId, start, end)

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Album Rank</CardTitle>
                <TrendingUpIcon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">#{summary.rank}</div>
                <p className="text-xs text-muted-foreground">Your most listened album</p>
            </CardContent>
        </Card>
    )
}

function FirstListenSummaryCard({releaseGroupId}) {
    const start = epoch()
    const end = roundedNow()
    const {data: summary, isPending} = useAutoQuery(getReleaseGroupSummary, releaseGroupId, start, end)

    if (isPending) {
        return
    }

    const discoveryDate = formatDateHumanReadable(new Date(summary.firstListen))

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Discovery</CardTitle>
                <SparklesIcon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{discoveryDate}</div>
                <p className="text-xs text-muted-foreground">Your first listen</p>
            </CardContent>
        </Card>
    )
}

export function ReleaseGroupPage() {
    const {releaseGroupId} = useParams()

    return (
        <div className="flex flex-col items-center gap-4 w-full">
            <Header releaseGroupId={releaseGroupId}/>
            <Summary releaseGroupId={releaseGroupId}/>
            <RecordingsGraph releaseGroupId={releaseGroupId}/>
            <div className="grid sm:grid-cols-2 gap-4">
                <TopRecordings releaseGroupId={releaseGroupId} start={epoch()} end={roundedNow()} limit="20"/>
            </div>
        </div>
    );
}
