import { getUnmatchedSummary } from "../ApiClient"
import { epoch, formatCount, roundedNow, useAutoQuery } from "../utils"
import { Card, CardContent } from "./ui/card"
import { CircleAlertIcon } from "lucide-react"

export function UnmatchedListensWidget() {
    const start = epoch()
    const end = roundedNow()

    const {data: summary, isPending} = useAutoQuery(getUnmatchedSummary, start, end)

    if (isPending || summary.listenCount === 0) {
        return
    }

    return (
        <Card className="border-2 border-red-600">
            <CardContent className="flex flex-row items-center gap-2">
                <CircleAlertIcon className="w-7 h-7"/>
                You have {formatCount(summary.listenCount)} unmatched listens
            </CardContent>
        </Card>
    )

}