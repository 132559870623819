import React from "react"
import { getTopArtists } from "../ApiClient"
import { useAutoQuery } from "../utils"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { ArtistCard } from "./ArtistCard"

export function TopArtists({tagId, start, end}) {
    const {data: groups, isPending} = useAutoQuery(getTopArtists, tagId, start, end)

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Your Top Artists</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center gap-4">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {groups.map((group, index) =>
                        <ArtistCard group={group} index={index} key={group.artist.id}/>)}
                </div>
            </CardContent>
        </Card>
    );
}
