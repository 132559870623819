import { useAutoQuery } from "../../utils"
import { getTopUnmatchedSpotifyArtists } from "../../ApiClient"
import { useLocation } from "react-router-dom"
import { Card, CardContent } from "../../components/ui/card"

function SpotifyArtist({spotifyArtist}) {
    const copyLink = () => {
        const url = `https://open.spotify.com/artist/${spotifyArtist.id}`
        navigator.clipboard.writeText(url)
    }

    return (
        <Card>
            <CardContent className="flex flex-row items-center gap-2">
                <img src={spotifyArtist.images[0]?.url} className="aspect-square rounded-md h-[10rem]" alt={spotifyArtist.name}/>
                <a className="text-xl font-semibold" href={`https://open.spotify.com/artist/${spotifyArtist.id}`} target="_blank" rel="noreferrer" onClick={copyLink}>{spotifyArtist.name}</a>
            </CardContent>
        </Card>
    )
}


export function DataFixerUnmatchedSpotifyArtistsPage() {
    const location = useLocation()
    const userId = new URLSearchParams(location.search).get("userId")

    const {data: artists, isPending} = useAutoQuery(getTopUnmatchedSpotifyArtists, userId, 1000)

    if (isPending) {
        return
    }

    return (
        <div className="flex flex-col gap-4">
            {artists.map(artist => <SpotifyArtist spotifyArtist={artist} key={artist.id}/>)}
        </div>
    )
}