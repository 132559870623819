import React from "react"
import { DateTime } from "luxon"
import { TopArtists } from "../TopArtists"
import { TopReleaseGroups } from "../TopReleaseGroups"
import { TopRecordings } from "../TopRecordings"
import { TopTags } from "../TopTags"

export function YearDashboardBody({year}) {
    const start = DateTime.local(year).startOf("year")
    const end = DateTime.local(year).endOf("year")

    return (
        <div className="w-full grid gap-4 sm:grid-cols-1 md:grid-cols-2">
            <TopArtists start={start} end={end}/>
            <TopReleaseGroups start={start} end={end}/>
            <TopRecordings start={start} end={end}/>
            <TopTags start={start} end={end}/>
        </div>
    );
}
