import React from "react"
import { Link, useParams } from "react-router-dom"
import { getArtist, getReleaseGroupsGraph } from "../ApiClient"
import { TopReleaseGroups } from "../components/TopReleaseGroups"
import { ListenOnSpotifyButton } from "../components/ListenOnSpotifyButton"
import { ArtistRankCard } from "../components/ArtistRankCard"
import { ArtistListeningTimeCard } from "../components/ArtistListeningTimeCard"
import { ArtistTotalListensCard } from "../components/ArtistTotalListensCard"
import { ArtistTopRecordingCard } from "../components/ArtistTopRecordingCard"
import { epoch, getArtistName, getReleaseGroupName, roundedNow, useAutoQuery } from "../utils"
import { TopRecordings } from "../components/TopRecordings"
import { Graph } from "../components/Graph"

function Header({artistId}) {
    const {data: artist, isPending} = useAutoQuery(getArtist, artistId)

    if (isPending) {
        return null
    }

    const name = getArtistName(artist)

    return (
        <div className="flex flex-row gap-6 items-start">
            <img
                src={artist.images[0]?.url}
                alt={name}
                className="rounded-full w-32 h-32 md:w-48 md:h-48 object-cover"
            />
            <div className="flex flex-col gap-4">
                <h1 className="text-3xl md:text-4xl font-bold">{name}</h1>
                <div className="text-xl text-gray-600 dark:text-gray-300">
                    {artist.tags
                        .map(tag => <TagLink tag={tag}/>)
                        .reduce((prev, curr) => (prev === null ? [curr] : [...prev, ", ", curr]), null)
                    }
                </div>
                {artist.spotifyUrl != null ? <ListenOnSpotifyButton url={artist.spotifyUrl}/> : null}
            </div>
        </div>
    )
}

function TagLink({tag}) {
    return (
        <Link to={`/genre/${tag.id}`} className="hover:text-purple-600">
            {tag.name}
        </Link>
    )
}

function Summary({artistId}) {
    return (
        <div className="grid w-full gap-4 md:grid-cols-2 lg:grid-cols-4">
            <ArtistTotalListensCard artistId={artistId}/>
            <ArtistListeningTimeCard artistId={artistId}/>
            <ArtistTopRecordingCard artistId={artistId}/>
            <ArtistRankCard artistId={artistId}/>
        </div>
    )
}

function ArtistGraph({artistId}) {
    const start = epoch()
    const end = roundedNow()
    const {data: graph, isPending} = useAutoQuery(getReleaseGroupsGraph, artistId, start, end)

    if (isPending) {
        return
    }

    return (
        <Graph
            graph={graph}
            nameFormatter={getReleaseGroupName}
            className="w-full h-[25rem]"
        />
    )
}

export function ArtistPage() {
    const {artistId} = useParams()
    const start = epoch()
    const end = roundedNow()

    return (
        <div className="flex flex-col items-center gap-4 w-full">
            <Header artistId={artistId}/>
            <Summary artistId={artistId}/>
            <ArtistGraph artistId={artistId}/>
            <div className="grid sm:grid-colds-1 md:grid-cols-2 gap-4 w-full">
                <TopReleaseGroups artistId={artistId} start={start} end={end}/>
                <TopRecordings artistId={artistId} start={start} end={end}/>
            </div>
        </div>
    );
}