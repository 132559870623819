import React from "react"
import { Button } from "../components/ui/button"
import { BarChart3Icon, ClockIcon, MusicIcon } from "lucide-react"
import { Link } from "react-router-dom"

export function HomePage() {
    return (
        <div className="w-full flex-1 flex flex-col gap-6 items-center justify-center text-center">
            <div className="flex flex-col gap-6 items-center max-w-2xl">
                <h1 className="text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-bold tracking-tighter bg-gradient-to-r from-purple-500 to-orange-500 text-transparent bg-clip-text">
                    Discover Your Music Journey
                </h1>
                <p className="text-lg sm:text-xl text-gray-600">
                    Unlock deep insights into your listening habits, explore your musical tastes, and connect with your
                    favorite artists like never before.
                </p>
                <div
                    className="flex flex-row gap-4 items-center justify-center">
                    <Link to="/login">
                        <Button className="bg-purple-600 text-white hover:bg-purple-700">Get Started</Button>
                    </Link>
                    <Button variant="outline" className="border-purple-600 text-purple-600 hover:bg-purple-100">Learn
                        More</Button>
                </div>
            </div>
            <div className="grid gap-8 grid-cols-1 sm:grid-cols-3 max-w-4xl">
                <div className="flex flex-col gap-3 items-center text-center">
                    <BarChart3Icon className="h-12 w-12 text-purple-600"/>
                    <h3 className="text-xl font-bold">In-depth Analytics</h3>
                    <p className="text-sm text-gray-600">Dive deep into your listening patterns with detailed charts and
                        statistics.</p>
                </div>
                <div className="flex flex-col gap-3 items-center text-center">
                    <ClockIcon className="h-12 w-12 text-purple-600"/>
                    <h3 className="text-xl font-bold">Listening History</h3>
                    <p className="text-sm text-gray-600">Track your music journey over time and rediscover forgotten
                        favorites.</p>
                </div>
                <div className="flex flex-col gap-3 items-center text-center">
                    <MusicIcon className="h-12 w-12 text-purple-600"/>
                    <h3 className="text-xl font-bold">Personalized Recommendations</h3>
                    <p className="text-sm text-gray-600">Get tailored music suggestions based on your unique taste
                        profile.</p>
                </div>
            </div>
        </div>
    );
}