import React from "react"
import { Link } from "react-router-dom"

export function ArtistCreditPart({part}) {
    if (part.artist) {
        return (
            <Link to={`/artist/${part.artist.id}`}
                  className="text-xl text-gray-600 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
                {part.name}
            </Link>
        )
    } else {
        return (
            <p className="text-xl text-gray-600 dark:text-gray-300">{part.name}</p>
        )
    }
}