import { useEffect, useState } from "react"
import { getTopUnmatchedSpotifyAlbumsGlobal } from "../../ApiClient"
import { UnmatchedSpotifyAlbum } from "./UnmatchedSpotifyAlbum"

export function TopUnmatchedSpotifyAlbums() {
    const [spotifyTracks, setSpotifyTracks] = useState([])

    useEffect(() => {
        getTopUnmatchedSpotifyAlbumsGlobal(250).then(setSpotifyTracks)
    }, [])

    return (
        <div className="flex flex-col gap-4">
            {spotifyTracks.map(spotifyAlbum => <UnmatchedSpotifyAlbum spotifyAlbum={spotifyAlbum}/>)}
        </div>
    )
}