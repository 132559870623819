import React from "react"
import { getTopRecordings } from "../ApiClient"
import { useAutoQuery } from "../utils"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { RecordingCard } from "./RecordingCard"

export function TopRecordings({artistId, releaseGroupId, tagId, start, end, discoveredAfter, limit}) {
    const {
        data: groups,
        isPending,
    } = useAutoQuery(getTopRecordings, artistId, releaseGroupId, tagId, start, end, discoveredAfter, limit || 10)

    if (isPending || groups.length === 0) {
        return null
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Your Top Tracks</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {groups.map((group, index) =>
                        <RecordingCard group={group} index={index} key={group.recording.id}/>)}
                </div>
            </CardContent>
        </Card>
    )
}