import { Card, CardContent } from "../../components/ui/card"
import { formatTrackDuration, useAutoQuery } from "../../utils"
import { attachRecordingIsrc, getSpotifyTrackRecordingGuess, getTopUnmatchedSpotifyTracks } from "../../ApiClient"
import { Button } from "../../components/ui/button"
import { useState } from "react"
import { ClockIcon } from "lucide-react"
import { useLocation } from "react-router-dom"

function AiGuess({spotifyTrack}) {
    const [recordingGuess, setRecordingGuess] = useState(null)

    const loadRecordingGuess = () => {
        getSpotifyTrackRecordingGuess(spotifyTrack.id).then(setRecordingGuess)
    }

    const attachIsrc = () => {
        attachRecordingIsrc(recordingGuess.recording.id, spotifyTrack.isrc)
    }

    if (recordingGuess === null) {
        return (
            <Button className="text-white bg-purple-500 hover:bg-purple-600" onClick={loadRecordingGuess}>
                Load AI Guess
            </Button>
        )
    } else if (recordingGuess.recording === null) {
        let spotifyAlbumUrl = `https://open.spotify.com/album/${spotifyTrack.album.id}`

        let harmonyUrl = new URL("https://harmony.pulsewidth.org.uk/release")
        harmonyUrl.searchParams.set("url", spotifyAlbumUrl)

        return (
            <div className="flex flex-col gap-2">
                <span className="text-xl font-semibold">No match</span>
                <span className="max-w-lg">{recordingGuess.message}</span>
                {spotifyTrack.artists.map(artist => {
                    const copyLink = () => {
                        const url = `https://open.spotify.com/artist/${artist.id}`
                        navigator.clipboard.writeText(url)
                    }

                    return (
                        <MusicbrainzLink path={`/search?query=${encodeURIComponent(artist.name)}&type=artist&method=indexed`}>
                            <Button className="text-white bg-purple-500 hover:bg-purple-600" onClick={copyLink}>
                                {artist.name}
                            </Button>
                        </MusicbrainzLink>
                    )
                })}
                <MusicbrainzLink/>
                <a href={harmonyUrl} target="_blank" rel="noreferrer">
                    <Button className="text-white bg-purple-500 hover:bg-purple-600">
                        Harmony
                    </Button>
                </a>
            </div>
        )
    } else {
        const recording = recordingGuess.recording
        return (
            <div className="flex flex-col gap-2">
                <span className="text-xl font-semibold">Match found</span>
                <MusicbrainzLink path={`/recording/${recording.id}`}>{recording.name}</MusicbrainzLink>
                <span>{recording.artistCredit?.name}</span>
                <div className="flex flex-row gap-1 items-center">
                    <ClockIcon className="w-4 h-4"/>
                    <span>{recording.length !== null ? formatTrackDuration(recording.length) : "?"}</span>
                </div>
                <span className="max-w-lg">{recordingGuess.message}</span>
                <Button className="text-white bg-purple-500 hover:bg-purple-600 w-fit" onClick={attachIsrc}>
                    Attach ISRC
                </Button>
            </div>
        )
    }
}

function SpotifyLink({path, className, children}) {
    return <a href={`https://open.spotify.com${path}`} target="_blank" rel="noreferrer" className={className}>{children}</a>
}

function MusicbrainzLink({path, className, children}) {
    return <a href={`https://musicbrainz.org${path}`} target="_blank" rel="noreferrer" className={className}>{children}</a>
}

function SpotifyTrack({spotifyTrack}) {
    return (
        <Card>
            <CardContent className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-8 h-[10rem]">
                    <img src={spotifyTrack.album.images?.[0]?.url} alt="alt" className="rounded-md"/>
                    <div className="flex flex-col justify-around">
                        <SpotifyLink path={`/track/${spotifyTrack.id}`} className="text-xl font-semibold">
                            {spotifyTrack.name}
                        </SpotifyLink>
                        <div className="flex flex-row gap-4">
                            {spotifyTrack.artists.map(artist =>
                                <SpotifyLink path={`/artist/${artist.id}`}>{artist.name}</SpotifyLink>)}
                        </div>
                        <SpotifyLink path={`/album/${spotifyTrack.album.id}`}>{spotifyTrack.album.name}</SpotifyLink>
                        <div className="flex flex-row gap-1 items-center">
                            <ClockIcon className="w-4 h-4"/>
                            <span>{formatTrackDuration(spotifyTrack.durationMs)}</span>
                        </div>
                        <span>{spotifyTrack.isrc}</span>
                    </div>
                </div>
                <AiGuess spotifyTrack={spotifyTrack}/>
            </CardContent>
        </Card>
    )
}

export function DataFixerUnmatchedSpotifyTracksPage() {
    const location = useLocation()
    const userId = new URLSearchParams(location.search).get("userId")

    const {data: spotifyTracks, isPending} = useAutoQuery(getTopUnmatchedSpotifyTracks, userId, 1000)

    if (isPending) {
        return
    }

    return (
        <div className="flex flex-col gap-4">
            {spotifyTracks.map(spotifyTrack => <SpotifyTrack spotifyTrack={spotifyTrack} key={spotifyTrack.id}/>)}
        </div>
    )
}