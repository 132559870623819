import React from "react"
import { Card, CardContent } from "../ui/card"
import { Button } from "../ui/button"

function ExtButton({url, text}) {
    return (
        <a href={url} target="_blank" rel="noreferrer">
            <Button className="bg-purple-500 hover:bg-purple-600 text-white">
                {text}
            </Button>
        </a>
    )
}

export function UnmatchedSpotifyAlbum({spotifyAlbum}) {
    let url = `https://open.spotify.com/album/${spotifyAlbum.id}`

    let dontunesUrl = `https://d.ontun.es/#/explorer/album/${spotifyAlbum.id}`

    let harmonyUrl = new URL("https://harmony.pulsewidth.org.uk/release")
    harmonyUrl.searchParams.set("url", url)

    return (
        <Card>
            <CardContent className="flex flex-row gap-4 items-center h-[10rem]">
                <a href={url} target="_blank" className="h-full"
                   rel="noreferrer">
                    <img
                        src={spotifyAlbum.images?.[0]?.url}
                        alt="album"
                        className="h-full rounded-lg object-cover aspect-square"
                    />
                </a>

                <div className="flex flex-col gap-4 w-[25rem]">
                    <p>{spotifyAlbum.name}</p>
                    <p className="font-bold">{spotifyAlbum.artists.map(artist => artist.name).join(", ")}</p>
                </div>
                <ExtButton text="dontunes" url={dontunesUrl}/>
                <ExtButton text="harmony" url={harmonyUrl}/>
            </CardContent>
        </Card>
    )
}