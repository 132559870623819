import { getTopTags } from "../ApiClient"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table"
import React from "react"
import { formatCount, useAutoQuery } from "../utils"
import { useNavigate } from "react-router-dom"

export function TopTag({listenGroup, index}) {
    const navigate = useNavigate()
    const tag = listenGroup.tag

    return (
        <TableRow onClick={() => navigate(`/genre/${tag.id}`)} className="cursor-pointer">
            <TableCell className="font-medium">{index + 1}</TableCell>
            <TableCell className="flex flex-row gap-2 items-center">{tag.name}</TableCell>
            <TableCell className="text-right">{formatCount(listenGroup.count)}</TableCell>
        </TableRow>
    )
}

export function TopTags({start, end}) {
    const {data: listenGroups, isPending} = useAutoQuery(getTopTags, start, end)

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Your Top Genres</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[50px]">#</TableHead>
                            <TableHead>Genre</TableHead>
                            <TableHead className="text-right">Plays</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {listenGroups.map((listenGroup, index) =>
                            <TopTag listenGroup={listenGroup} index={index} key={listenGroup.tag.name}/>)}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )

}