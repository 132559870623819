import axios from "axios"
import { tokenStore } from "./stores/ApiTokenStore"

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
})

httpClient.interceptors.request.use(
    request => {
        request.headers.Authorization = tokenStore.getState().token
        return request
    },
    error => {
        return Promise.reject(error)
    },
)

httpClient.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error?.response?.status === 401) {
            tokenStore.getState().clearToken()
        }
        return Promise.reject(error)
    },
)

export async function getCurrentUser() {
    let response = await httpClient.get("/users/@me")
    return response.data
}

export async function getUser(userId) {
    let response = await httpClient.get(`/users/${userId}`)
    return response.data
}

export async function getArtist(id) {
    let response = await httpClient.get(`/artists/${id}`)
    return response.data
}

export async function getReleaseGroup(id) {
    let response = await httpClient.get(`/releaseGroups/${id}`)
    return response.data
}

export async function getRecording(id) {
    let response = await httpClient.get(`/recordings/${id}`)
    return response.data
}

export async function getRecordingReleaseGroups(id) {
    let response = await httpClient.get(`/recordings/${id}/releaseGroups`)
    return response.data
}

export async function getTopArtists(tagId, start, end) {
    let response = await httpClient.get("/listens/insights/topArtists", {
        params: {
            tagId: tagId,
            start: start.toMillis(),
            end: end.toMillis(),
            limit: 10,
        },
    })
    return response.data
}

export async function getTopReleaseGroups(artistId, start, end, limit) {
    let response = await httpClient.get("/listens/insights/topReleaseGroups", {
        params: {
            artistId: artistId,
            start: start.toMillis(),
            end: end.toMillis(),
            limit: limit,
        },
    })
    return response.data
}

export async function getTopRecordings(artistId, releaseGroupId, tagId, start, end, discoveredAfter, limit) {
    let response = await httpClient.get("/listens/insights/topRecordings", {
        params: {
            artistId: artistId,
            releaseGroupId: releaseGroupId,
            tagId: tagId,
            start: start.toMillis(),
            end: end.toMillis(),
            discoveredAfter: discoveredAfter,
            limit: limit,
        },
    })
    return response.data
}

export async function getTopYears(limit) {
    let response = await httpClient.get("/listens/insights/topYears", {
        params: {
            limit: limit,
        },
    })
    return response.data
}

export async function startSpotifyLogin() {
    let response = await httpClient.post("/login/spotify/start")
    return response.data
}

export async function finishSpotifyLogin(code) {
    let response = await httpClient.post("/login/spotify/finish", {
        code: code,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    return response.data
}

export async function uploadListeningHistory(file) {
    let formData = new FormData()
    formData.append("file", file)
    let response = await httpClient.post("/spotify/backfillImports", formData)
    return response.data
}

export async function getSpotifyBackfillImport(id) {
    let response = await httpClient.get(`/spotify/backfillImports/${id}`)
    return response.data
}

export async function getTopUnmatchedSpotifyAlbumsGlobal(limit) {
    let response = await httpClient.get("/dataFixer/spotify/topUnmatchedAlbums/global", {
        params: {
            limit: limit,
        },
    })
    return response.data
}

export async function getTopUnmatchedSpotifyTracks(userId, limit) {
    let response = await httpClient.get("/dataFixer/spotify/topUnmatchedTracks", {
        params: {
            userId: userId,
            limit: limit,
        },
    })
    return response.data
}

export async function getTopUnmatchedSpotifyArtists(userId, limit) {
    let response = await httpClient.get("/dataFixer/spotify/topUnmatchedArtists", {
        params: {
            userId: userId,
            limit: limit,
        },
    })
    return response.data
}

export async function getUnmatchedSpotifySummary() {
    let response = await httpClient.get("/dataFixer/spotify/unmatchedSummary")
    return response.data
}

export async function getTopReleaseDecades() {
    let response = await httpClient.get("/listens/insights/topReleaseDecades")
    return response.data
}

export async function getArtistSummary(artistId, start, end) {
    let response = await httpClient.get(`/artists/${artistId}/summary`, {
        params: {
            start: start.toMillis(),
            end: end.toMillis(),
        },
    })
    return response.data
}

export async function getSummary(start, end) {
    let response = await httpClient.get("/listens/insights/summary", {
        params: {
            start: start.toMillis(),
            end: end.toMillis(),
        },
    })
    return response.data
}

export async function getUnmatchedSummary(start, end) {
    let response = await httpClient.get("/listens/insights/unmatchedSummary", {
        params: {
            start: start.toMillis(),
            end: end.toMillis(),
        },
    })
    return response.data
}

export async function getRecordingSummary(recordingId, start, end) {
    let response = await httpClient.get(`/recordings/${recordingId}/summary`, {
        params: {
            start: start.toMillis(),
            end: end.toMillis(),
        },
    })
    return response.data
}

export async function getReleaseGroupSummary(recordingId, start, end) {
    let response = await httpClient.get(`/releaseGroups/${recordingId}/summary`, {
        params: {
            start: start.toMillis(),
            end: end.toMillis(),
        },
    })
    return response.data
}

export async function getTopTags(start, end) {
    let response = await httpClient.get(`/listens/insights/topTags`, {
        params: {
            start: start.toMillis(),
            end: end.toMillis(),
            limit: 10,
        },
    })
    return response.data
}

export async function getSpotifyBackfillImports() {
    let response = await httpClient.get("/spotify/backfillImports")
    return response.data
}

export async function getSpotifyTrackRecordingGuess(spotifyTrackId) {
    let response = await httpClient.get("/dataFixer/spotify/trackRecordingGuess", {
        params: {
            spotifyTrackId: spotifyTrackId,
        },
    })
    return response.data
}

export async function attachRecordingIsrc(recordingId, isrc) {
    await httpClient.post("/dataFixer/musicbrainz/recordingIsrcSubmit", {
        recordingId: recordingId,
        isrc: isrc,
    })
}

export async function getTag(tagId) {
    let response = await httpClient.get(`/tags/${tagId}`)
    return response.data
}

export async function search(query) {
    let response = await httpClient.get(`/search`, {
        params: {
            query: query,
        },
    })
    return response.data
}

export async function getArtistsGraph(start, end) {
    let response = await httpClient.get(`/listens/insights/artistsGraph`, {
        params: {
            start: start.toMillis(),
            end: end.toMillis(),
        },
    })
    return response.data
}

export async function getArtistGraph(artistId, start, end) {
    let response = await httpClient.get(`/listens/insights/artistGraph`, {
        params: {
            artistId: artistId,
            start: start.toMillis(),
            end: end.toMillis(),
        },
    })
    return response.data
}

export async function getReleaseGroupsGraph(artistId, start, end) {
    let response = await httpClient.get(`/listens/insights/releaseGroupsGraph`, {
        params: {
            artistId: artistId,
            start: start.toMillis(),
            end: end.toMillis(),
        },
    })
    return response.data
}

export async function getReleaseGroupGraph(releaseGroupId, start, end) {
    let response = await httpClient.get(`/listens/insights/releaseGroupGraph`, {
        params: {
            releaseGroupId: releaseGroupId,
            start: start.toMillis(),
            end: end.toMillis(),
        },
    })
    return response.data
}

export async function getRecordingsGraph(artistId, releaseGroupId, start, end) {
    let response = await httpClient.get(`/listens/insights/recordingsGraph`, {
        params: {
            artistId: artistId,
            releaseGroupId: releaseGroupId,
            start: start.toMillis(),
            end: end.toMillis(),
        },
    })
    return response.data
}

export async function sendFriendRequest(userId) {
    let response = await httpClient.post(`/friendRequests/outgoing`, {
        userId: userId,
    })
    return response.data
}

export async function cancelFriendRequest(userId) {
    let response = await httpClient.delete(`/friendRequests/outgoing/${userId}`)
    return response.data
}

export async function acceptFriendRequest(userId) {
    let response = await httpClient.post(`/friendRequests/incoming/${userId}/accept`)
    return response.data
}

export async function rejectFriendRequest(userId) {
    let response = await httpClient.post(`/friendRequests/incoming/${userId}/reject`)
    return response.data
}

export async function unfriend(userId) {
    let response = await httpClient.delete(`/friends/${userId}`)
    return response.data
}