import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { finishSpotifyLogin } from "../ApiClient"
import { tokenStore } from "../stores/ApiTokenStore"

export function AuthPage() {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const completeLogin = async () => {
            const params = new URLSearchParams(location.search)
            const code = params.get("code")

            if (code) {
                try {
                    let response = await finishSpotifyLogin(code)
                    // Handle success - you might want to redirect or save the authentication data
                    tokenStore.getState().setToken(response.token)
                    // Redirect to dashboard after successful login
                    navigate("/dashboard")
                } catch (error) {
                    console.error("Failed to complete login:", error)
                    // Handle errors, such as by showing a message or redirecting to an error page
                }
            }
        }

        completeLogin()
    }, [location, navigate])

    return (
        <div>Authenticating...</div>
    )
}
