import React from "react"
import { SpotifyLoginButton } from "../components/SpotifyLoginButton"
import { AppleMusicLoginButton } from "../components/AppleMusicLoginButton"
import { Card, CardContent, CardHeader } from "../components/ui/card"

export function LoginPage() {
    return (
        <div className="w-full flex-1 flex flex-col items-center justify-center">
            <Card className="flex flex-col items-center gap-6">
                <CardHeader>
                    <h2 className="text-3xl font-bold">Login to insights.fm</h2>
                </CardHeader>
                <CardContent className="flex flex-col gap-2 w-fit">
                    <SpotifyLoginButton/>
                    <AppleMusicLoginButton/>
                </CardContent>
            </Card>
        </div>
    )
}