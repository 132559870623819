import * as React from "react"

import { cn } from "../../utils"

const Card = React.forwardRef(({className, ...props}, ref) => (
    <div
        ref={ref}
        className={cn("rounded-lg border bg-card text-card-foreground shadow-sm p-4", className)}
        {...props} />
))
Card.displayName = "Card"

const CardHeader = React.forwardRef(({className, ...props}, ref) => (
    <div
        ref={ref}
        className={cn("flex flex-col pb-2", className)}
        {...props} />
))
CardHeader.displayName = "CardHeader"

const CardTitle = React.forwardRef(({className, ...props}, ref) => (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <h3
        ref={ref}
        className={cn("text-2xl font-semibold leading-none tracking-tight", className)}
        {...props} />
))
CardTitle.displayName = "CardTitle"

const CardDescription = React.forwardRef(({className, ...props}, ref) => (
    <p
        ref={ref}
        className={cn("text-sm text-muted-foreground", className)}
        {...props} />
))
CardDescription.displayName = "CardDescription"

const CardContent = React.forwardRef(({className, ...props}, ref) => (
    <div ref={ref} className={className} {...props} />
))
CardContent.displayName = "CardContent"

const CardFooter = React.forwardRef(({className, ...props}, ref) => (
    <div
        ref={ref}
        className={cn("flex items-center pt-4", className)}
        {...props} />
))
CardFooter.displayName = "CardFooter"

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent }
